<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label>
        <router-link :to="{ name: 'userPermission' }">{{ $t("UsersPermissions") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $route.params.id ? $t("Edit") : $t("Add") }}</label>
    </div>

    <v-container>
      <v-row class="mt-3">
        <v-col md="12">
          <v-alert v-if="id" border="top" color="green lighten-2" dark>
            <h4>{{ $t("EditPermission") }}</h4></v-alert
          >
          <v-alert v-else border="top" color="blue lighten-2" dark>
            <h4>{{ $t("AddNewPermission") }}</h4></v-alert
          >
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col class="py-0" :cols="3">
          <div
            :class="{
              'has-error': errors.has(`addEditValidation.name`),
            }"
          >
            <label>{{ $t("RoleName") }}</label>
            <v-text-field
              class="d-block my-2"
              type="text"
              name="name"
              data-vv-scope="addEditValidation"
              v-validate="'required'"
              :data-vv-as="$t('RoleName')"
              hide-details
              dense
              outlined
              v-model="formData.name"
            ></v-text-field>
          </div>

          <div class="help-block" v-if="errors.has(`addEditValidation.name`)">
            {{ errors.first(`addEditValidation.name`) }}
          </div>
        </v-col>
      </v-row>

      <div class="permissionsCard mt-5">
        <v-row class="mt-1">
          <v-col md="12">
            <el-checkbox
              @change="changeAllPermissionsCheck(permissions_send.all_checks)"
              v-model="permissions_send.all_checks"
              class="mx-2"
            ></el-checkbox>
            <span>{{ $t("AllPermissions") }}</span>
          </v-col>
        </v-row>

        <div class="mt-3">
          <h3>{{ $t("Home") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                v-model="permissions_send.get_home"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("Divisions") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="
                  changeAllCheck('division', permissions_send.all_division)
                "
                v-model="permissions_send.all_division"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_division"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_division"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_division"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_division"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("Groups") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="changeAllCheckGroup(permissions_send.all_group)"
                v-model="permissions_send.all_group"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_group"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_group"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_group"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_group"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("NutritionFacts") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="
                  changeAllCheck(
                    'nutrition_fact',
                    permissions_send.all_nutrition_fact
                  )
                "
                v-model="permissions_send.all_nutrition_fact"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_nutrition_fact"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_nutrition_fact"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_nutrition_fact"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_nutrition_fact"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("Cuisines") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="
                  changeAllCheck('cuisine', permissions_send.all_cuisine)
                "
                v-model="permissions_send.all_cuisine"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_cuisine"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_cuisine"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_cuisine"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_cuisine"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("Tools") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="changeAllCheck('tool', permissions_send.all_tool)"
                v-model="permissions_send.all_tool"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_tool"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_tool"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_tool"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_tool"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("Units") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="changeAllCheck('unit', permissions_send.all_unit)"
                v-model="permissions_send.all_unit"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_unit"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_unit"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_unit"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_unit"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("Countries") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="
                  changeAllCheck('country', permissions_send.all_country)
                "
                v-model="permissions_send.all_country"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_country"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_country"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_country"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_country"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("Cities") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="changeAllCheck('city', permissions_send.all_city)"
                v-model="permissions_send.all_city"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_city"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_city"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_city"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_city"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("Branches") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="changeAllCheck('branch', permissions_send.all_branch)"
                v-model="permissions_send.all_branch"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_branch"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_branch"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_branch"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_branch"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("Periods") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="changeAllCheck('period', permissions_send.all_period)"
                v-model="permissions_send.all_period"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_period"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_period"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_period"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_period"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("BankNames") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="
                  changeAllCheck('bank_name', permissions_send.all_bank_name)
                "
                v-model="permissions_send.all_bank_name"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_bank_name"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_bank_name"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_bank_name"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_bank_name"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("Companies") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="
                  changeAllCheck('company', permissions_send.all_company)
                "
                v-model="permissions_send.all_company"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_company"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_company"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_company"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_company"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("GroupNames") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="
                  changeAllCheck('group_name', permissions_send.all_group_name)
                "
                v-model="permissions_send.all_group_name"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_group_name"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_group_name"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_group_name"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_group_name"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("Packages") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="
                  changeAllCheck('package', permissions_send.all_package)
                "
                v-model="permissions_send.all_package"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_package"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_package"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_package"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_package"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("Ingredients") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="
                  changeAllCheck('ingredient', permissions_send.all_ingredient)
                "
                v-model="permissions_send.all_ingredient"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_ingredient"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_ingredient"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_ingredient"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_ingredient"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("Recipies") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="
                  changeAllCheck('recipie', permissions_send.all_recipie)
                "
                v-model="permissions_send.all_recipie"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_recipie"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_recipie"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_recipie"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_recipie"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("Menues") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="changeAllCheck('menu', permissions_send.all_menu)"
                v-model="permissions_send.all_menu"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_menu"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_menu"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_menu"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_menu"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("Subscribes") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="
                  changeAllCheckSubscribe(permissions_send.all_subscribe)
                "
                v-model="permissions_send.all_subscribe"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_subscribe"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_subscribe"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_subscribe"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_subscribe"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>

              <el-checkbox
                v-model="permissions_send.start_subscribe"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Start") }}</span>

              <el-checkbox
                v-model="permissions_send.stop_subscribe"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Stop") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("Users") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="changeAllCheck('admin', permissions_send.all_admin)"
                v-model="permissions_send.all_admin"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_admin"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_admin"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_admin"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_admin"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("UsersPermissions") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="
                  changeAllCheck('permission', permissions_send.all_permission)
                "
                v-model="permissions_send.all_permission"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.get_permission"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>

              <el-checkbox
                v-model="permissions_send.store_permission"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Save") }}</span>

              <el-checkbox
                v-model="permissions_send.update_permission"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Update") }}</span>

              <el-checkbox
                v-model="permissions_send.delete_permission"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Delete") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("WorkGroupActivities") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                v-model="permissions_send.get_activity"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("View") }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-5">
          <h3>{{ $t("Reports") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="changeAllCheckExports(permissions_send.all_export)"
                v-model="permissions_send.all_export"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.export_kitchen_today"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("CookingToday") }}</span>

              <el-checkbox
                v-model="permissions_send.export_deliveries"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("DelegateToday") }}</span>

              <el-checkbox
                v-model="permissions_send.export_quantities_today"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("QuantitiesToday") }}</span>

              <el-checkbox
                v-model="permissions_send.export_subscription_data"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("SubscribtionsData") }}</span>

              <el-checkbox
                v-model="permissions_send.export_renwal_data"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("ResumeToday") }}</span>
            </v-col>
          </v-row>
        </div>

          <div class="mt-5">
          <h3>{{ $t("ImportSubscribes") }}</h3>

          <v-row class="mt-1">
            <v-col
              md="12"
              style="border: 1px solid #ccc; background: #111123; color: #fff"
            >
              <el-checkbox
                @change="changeAllCheckImports(permissions_send.all_import)"
                v-model="permissions_send.all_import"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("All") }}</span>

              <el-checkbox
                v-model="permissions_send.import_customer"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Customers") }}</span>

              <el-checkbox
                v-model="permissions_send.import_subscribe"
                class="mx-2"
              ></el-checkbox>
              <span>{{ $t("Subscribes") }}</span>

            </v-col>
          </v-row>
        </div>

        <v-row class="float-left mt-10 mb-5">
          <v-btn
            @click="savepermissions"
            class="mr-2 ml-2"
            color="primary"
            dark
            outlined
            small
          >
            {{ $t("Save") }}
          </v-btn>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "userPermissionAddEdit",
  data() {
    return {
      id: this.$route.params.id ? this.$route.params.id : null,
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : [],
      formData: {
        name: "",
        permissions: [],
      },
      permissions_send: {
        all_checks: false,
        get_home: false,
        all_division: false,
        get_division: false,
        store_division: false,
        update_division: false,
        delete_division: false,

        all_group: false,
        get_group: false,
        store_group: false,
        update_group: false,
        delete_group: false,

        all_nutrition_fact: false,
        get_nutrition_fact: false,
        store_nutrition_fact: false,
        update_nutrition_fact: false,
        delete_nutrition_fact: false,

        all_cuisine: false,
        get_cuisine: false,
        store_cuisine: false,
        update_cuisine: false,
        delete_cuisine: false,

        all_tool: false,
        get_tool: false,
        store_tool: false,
        update_tool: false,
        delete_tool: false,

        all_unit: false,
        get_unit: false,
        store_unit: false,
        update_unit: false,
        delete_unit: false,

        all_country: false,
        get_country: false,
        store_country: false,
        update_country: false,
        delete_country: false,

        all_city: false,
        get_city: false,
        store_city: false,
        update_city: false,
        delete_city: false,

        all_branch: false,
        get_branch: false,
        store_branch: false,
        update_branch: false,
        delete_branch: false,

        all_period: false,
        get_period: false,
        store_period: false,
        update_period: false,
        delete_period: false,

        all_bank_name: false,
        get_bank_name: false,
        store_bank_name: false,
        update_bank_name: false,
        delete_bank_name: false,

        all_company: false,
        get_company: false,
        store_company: false,
        update_company: false,
        delete_company: false,

        all_group_name: false,
        get_group_name: false,
        store_group_name: false,
        update_group_name: false,
        delete_group_name: false,

        all_package: false,
        get_package: false,
        store_package: false,
        update_package: false,
        delete_package: false,

        all_ingredient: false,
        get_ingredient: false,
        store_ingredient: false,
        update_ingredient: false,
        delete_ingredient: false,

        all_recipie: false,
        get_recipie: false,
        store_recipie: false,
        update_recipie: false,
        delete_recipie: false,

        all_menu: false,
        get_menu: false,
        store_menu: false,
        update_menu: false,
        delete_menu: false,

        all_subscribe: false,
        get_subscribe: false,
        store_subscribe: false,
        update_subscribe: false,
        delete_subscribe: false,
        start_subscribe: false,
        stop_subscribe: false,

        all_admin: false,
        get_admin: false,
        store_admin: false,
        update_admin: false,
        delete_admin: false,

        all_permission: false,
        get_permission: false,
        store_permission: false,
        update_permission: false,
        delete_permission: false,

        get_activity: false,

        all_export: false,
        export_deliveries: false,
        export_renwal_data: false,
        export_kitchen_today: false,
        export_quantities_today: false,
        export_subscription_data: false,

        all_import:false,
        import_customer: false,
        import_subscribe: false,
      },
    };
  },
  methods: {
    changeAllPermissionsCheck(chk_all) {
      for (let key in this.permissions_send) {
        // if (!key.includes("all_")) {
        this.permissions_send[key] = chk_all;
        // }
      }
    },
    changeAllCheck(perKey, chk_all) {
      for (let key in this.permissions_send) {
        if (!key.includes("all_") && key.includes(`_${perKey}`)) {
          this.permissions_send[key] = chk_all;
        }
      }
    },
    changeAllCheckGroup(chk_all) {
      this.permissions_send["get_group"] = chk_all;
      this.permissions_send["store_group"] = chk_all;
      this.permissions_send["update_group"] = chk_all;
      this.permissions_send["delete_group"] = chk_all;
    },
    changeAllCheckSubscribe(chk_all) {
      this.permissions_send["get_subscribe"] = chk_all;
      this.permissions_send["store_subscribe"] = chk_all;
      this.permissions_send["update_subscribe"] = chk_all;
      this.permissions_send["delete_subscribe"] = chk_all;
      this.permissions_send["start_subscribe"] = chk_all;
      this.permissions_send["stop_subscribe"] = chk_all;
    },
    changeAllCheckExports(chk_all) {
      this.permissions_send["export_deliveries"] = chk_all;
      this.permissions_send["export_renwal_data"] = chk_all;
      this.permissions_send["export_kitchen_today"] = chk_all;
      this.permissions_send["export_quantities_today"] = chk_all;
      this.permissions_send["export_subscription_data"] = chk_all;
    },
    changeAllCheckImports(chk_all) {
      this.permissions_send["import_customer"] = chk_all;
      this.permissions_send["import_subscribe"] = chk_all;
    },
    savepermissions() {
      let permissionsToSend = [];

      for (let key in this.permissions_send) {
        if (!key.includes("all_") && this.permissions_send[key] == true) {
          permissionsToSend.push(key);
        }
      }

      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        let sendData = JSON.parse(JSON.stringify(this.formData));

        sendData.permissions = permissionsToSend;

        if (this.$route.params.id) {
          //edit
          sendData.id = this.$route.params.id;
          this.$store
            .dispatch(`userPermission/updateData`, sendData)
            .then((res) => {
              this.notifySuccess("Update", res);
              this.$router.push({ name: "userPermission" });
            }).catch(error => {
            this.notifyError('Error',error);
          })
        } else {
          this.$store
            .dispatch(`userPermission/saveData`, sendData)
            .then((res) => {
              this.notifySuccess("Save", res);
              this.$router.push({ name: "userPermission" });
            }).catch(error => {
            this.notifyError('Error',error);
          })
        }
      });
    },
  },
  created() {
    if (this.$route.params.id) {
      this.$store
        .dispatch(`userPermission/findData`, this.$route.params.id)
        .then((res) => {

          res.data.permissions.forEach(per => {
               this.permissions_send[per] = true;
          });
          
          this.formData = res.data;

        });
    }
  },
};
</script>


<style>
.permissionsCard span {
  font-size: 14px;
}
</style>
